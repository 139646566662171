@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.faq-section {
  padding: 32px 0;
}

.button {
  height: 47px;
  width: 293px;
}

.link-container {
  padding-top: 32px;
}

.expand-icon-selected {
  color: #30f;
}

.title {
  margin-bottom: 32px;
  text-align: left;
}

.faq-section-gray {
  background: var.$color-neutral-25;
}

.faq-section-highlight-blue {
  background: var.$color-blue-50;
}

@media screen and (min-width: 1024px) {
  .faq-section {
    padding: 32px 0;
  }
}
